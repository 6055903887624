<template>
<v-dialog v-if="!!group" persistent width="600" v-model="dialog">
  <v-toolbar>
    <div class="text-subtitle-1 font-weight-bold">新增群组</div>
    <v-spacer></v-spacer>
    <v-icon @click="dialog=false">mdi-close</v-icon>
  </v-toolbar>
  <v-card>
    <v-container fluid>
      <div class="text-subtitle-2 font-weight-bold pa-4 mt-n2">群组名称<span class="red--text">*</span></div>
      <v-text-field v-model="group.team_name" height="40" class="mx-4 mt-n4" placeholder="请输入群组名称" dense outlined></v-text-field>
      <div class="px-4">
        <div class="text-subtitle-2 font-weight-bold mt-n3">可使用人</div>
        <div class="d-flex">
          <v-radio-group class="mt-1" color="blue" row v-model="importUserMode">
            <v-radio :value="0">
              <template v-slot:label>
                <div class="text-subtitle-2">全部人员</div>
              </template>
            </v-radio>
            <v-radio :value="1">
              <template v-slot:label>
                <div class="text-subtitle-2">指定人员</div>
              </template>
            </v-radio>
          </v-radio-group>
          <v-btn @click="openPicker" v-if="importUserMode" class="px-2" color="grey" outlined width="330">
            <div v-if="members.users.length==0 && members.departs.length==0">请选择指定人员</div>
            <div class="d-flex black--text" v-else>
              <div v-for="(user, i) in members.users" :key="user"><span v-if="i!=0">,</span>{{ user | username }}</div>
              <div v-for="(depart, i) in members.departs" :key="depart">,{{ depart | department }}</div>
            </div>
            <v-spacer></v-spacer>
            <v-icon>mdi-menu-down</v-icon>
          </v-btn>
        </div>
      </div>
      <div class="px-4 d-flex">
        <v-spacer></v-spacer>
        <v-btn class="mx-1" @click="dialog=false">取消</v-btn>
        <v-btn :disabled="!group.team_name" @click="agree" class="mx-1" color="primary">确定</v-btn>
      </div>
    </v-container>
  </v-card>
  <user-department-team-picker @selected="saveMembers" ref="userDepartmentTeamPicker"></user-department-team-picker>
</v-dialog>
</template>

<script>
import {
  mapActions,
  mapGetters
} from 'vuex';
export default {
  name: 'NewGroupDlg',
  components: {
    UserDepartmentTeamPicker: () => import('@/components/common/UserDepartmentTeamPicker.vue'),
  },
  props: ['group'],
  data: () => ({
    dialog: false,
    importUserMode: 0,
  }),
  computed: {
    ...mapGetters('user', ['users']),
    members: function () {
      if (!this.group) return {};
      const tempArray = this.group.team_collaborates.split(',');
      let users = [];
      let departs = [];
      tempArray.filter(id => {
        if (id.includes('u')) {
          users.push(id.substring(1));
        } else if (id.includes('d')) {
          departs.push(id.substring(1));
        }
      });
      return {
        users: users,
        departs: departs
      };
    }
  },
  methods: {
    open(collaborates = null) {
      this.dialog = true;
      this.importUserMode = !!collaborates ? 1 : 0;
    },
    agree() {
      if (!!this.group.team_id) {
        this.$emit('agree', 'update');
      } else {
        this.$emit('agree', 'create');
      }
      this.dialog = false;
    },
    openPicker() {
      this.$refs.userDepartmentTeamPicker.openDialog(this.group.team_collaborates);
    },
    saveMembers(e) {
      let temp = [];
      let users = [];
      e.user.map(u => {
        temp.push(`u${u}`);
        users.push(u);
      });
      e.depart.map(d => {
        temp.push(`d${d}`);
        this.users.filter(user => user.deptId == d).map(user => {
          users.push(user.id);
        })
      })
      this.group.team_collaborates = temp.join();
      this.group.team_members = users.join();
      this.agree();
    }
  }
}
</script>
